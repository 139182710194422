import { render, staticRenderFns } from "./RequestsCard.vue?vue&type=template&id=4658617e&scoped=true&"
import script from "./RequestsCard.vue?vue&type=script&lang=js&"
export * from "./RequestsCard.vue?vue&type=script&lang=js&"
import style0 from "./RequestsCard.vue?vue&type=style&index=0&id=4658617e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4658617e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VRow})
